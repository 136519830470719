import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { cDataService } from "src/app/services/data.service";
import * as data from './data.json';
import * as text from './text.json';
import * as search from './search.json';


interface EnumOption {
  name: string;
  displayName: string;
}

interface BaseProperty {
  name: string;
  displayName: string;
  required?: boolean;
}

interface EnumProperty extends BaseProperty {
  type: 'enum';
  enums: EnumOption[];
}

interface StringProperty extends BaseProperty {
  type: 'string';
}

interface BooleanProperty extends BaseProperty {
  type: 'boolean';
}
interface Prompt {
  name: string;
  displayName: string;
  fileTypes?: string[];
}

type PropertyType = 'enum' | 'string' | 'boolean';
type Property = EnumProperty | StringProperty | BooleanProperty;

interface Activity {
  name: string;
  title: string;
  required: boolean;
  enabled?: boolean;
  description: string;
  properties?: Property[];
  defaultPromptName?: string;
  prompts?: Prompt[];
  comment?: string;
}
interface PropertyConfig {
  name: string;
  displayName: string;
  type: string;
  value?: any;
  array?: boolean;
  required?: boolean;
  enums?: Array<{name: string, displayName: string}>;
  uiComponent?: string;
  min?: number;
  max?: number;
  step?: number;
}

interface WorkflowData {
  name?: string;
  title?: string;
  activities?: Activity[];
}
@Component({
  selector: 'app-schema-workflow',
  templateUrl: './schema-workflow.component.html',
  styleUrls: ['./schema-workflow.component.scss']
})
export class SchemaWorkflowComponent implements OnInit{
  @Input() file;
  @Input() schemaProperty : boolean;
  @Input() WorkflowType
  searchQAProperties: PropertyConfig[] = [];
  customSearchQAProps : PropertyConfig[] = [
    {
       "name":"useSummaryForContext",
       "displayName":"Use Summary for Context",
       "type":"boolean"
    },
    {
       "name":"numberOfSemanticMatchingResults",
       "displayName":"Number of Semantic Matching Results",
       "type":"integer"
    },
    {
       "name":"numberOfTermSearchResults",
       "displayName":"Number of Term Search Results",
       "type":"integer"
    },
    {
       "name":"resultDisplayPolicy",
       "displayName":"Result Display Policy",
       "type":"enum",
       "value" : "page",
       "enums":[
          {
             "name":"page",
             "displayName":"One Result Per Page"
          },
          {
             "name":"chapter",
             "displayName":"One Result Per Chapter"
          }
       ],
       "uiComponent" : "radio"
    },
    {
       "name":"modelTemperature",
       "displayName":"Model Temperature",
       "type":"range",
       "value" : 0.2,
       "min" : 0,
       "max" : 2,
       "step" : 0.1,
       "uiComponent" : "slider"
    },
    {
       "name":"customSearchFields",
       "displayName":"Custom Search Fields",
       "type":"string",
       "array" : true,
       "uiComponent" : "taglist"
    }
 ];
  noSchemaToShow: boolean = false;
  searchQAPropertiesForm: FormGroup;
  selectedItems: string[] = [];
  bLoading: boolean = false;
  showWorkflow : boolean;
  bResponseAlert: boolean;
  resMessage: { message: any; responseType: any; color: any };
  private modalRef: NgbModalRef | null = null; 
  customTags: string[] = [];
  workflowForm: FormGroup;
  formErrors: { [key: string]: string } = {};
  workflowData : WorkflowData = {};
  @Output() schemaUpdated = new EventEmitter<boolean>();
  showPromptSection:boolean=false;
  selectedActivity : string = "";
  completeWorkFlowList: any;
  constructor(
    private dataService: cDataService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    
  ) {
    this.workflowForm = this.fb.group({});
  }
  rangeLabels: number[] = [];
  // ****************************************** ngOnInit ***************************************

  ngOnInit(): void {
      this.getWorkFlow();
  }

  // ****************************************** Api's ***************************************

  getWorkFlow() {
    this.bLoading = true;
    this.dataService.cDataService_GetWorkflow(this.schemaProperty ? this.file?.targetId : this.file?.id + '?workflowType=' + this.WorkflowType).subscribe({
      next: (result) => {
        if (result.status == "failed") {
          this.bLoading = false;
          this.noSchemaToShow = true;
        } else {
          if (result?.content && typeof result?.content === 'string') {
            result.content = JSON.parse(result.content);
          }
          this.workflowData = result?.content as WorkflowData;
          this.selectedActivity = this.workflowData?.activities[0]?.name;
          this.completeWorkFlowList=result;
            this.initializeForm();
            this.bLoading = false;
        }
      },
      error: (error) => {
        this.bLoading = false;
        console.error("getWorkFlow : Error ==>", error);
      },
    });
  }


  initializeForm() {
    const group: any = {};
    
    this.workflowData?.activities?.forEach(activity => {
      // Add enabled control with validators if activity is required
      group[`${activity.name}_enabled`] = [{
        value: activity?.required == true ? true : activity.enabled ?? true,
        disabled: false
      }];

      if(activity.name == 'searchQA' && activity?.properties)
        {
          if(!Array.isArray(activity?.properties))
            {
              this.searchQAProperties = this.customSearchQAProps;
            }
            else
            {
              this.searchQAProperties = activity.properties;
            }
          this.initializeSearchQNAForm();
          if (this.searchQAProperties.some(prop => prop.type === 'range')) {
            this.initializeRangeLabels();
          }
        }
        else if(activity.name == 'searchQA' && !activity?.properties)
        {
          this.searchQAProperties = this.customSearchQAProps;
          this.initializeSearchQNAForm();
          if (this.searchQAProperties.some(prop => prop.type === 'range')) {
            this.initializeRangeLabels();
          }
        }
      
      if (activity?.properties && activity.name != 'searchQA' && activity?.properties?.length > 0) {
        activity?.properties?.forEach(prop => {
          let defaultValue: any;
          let validators = [];
          
          switch(prop.type) {
            case 'boolean':
              prop['value'] ? defaultValue = prop['value'] : defaultValue = false;
              if (prop.required) {
                validators.push(Validators.requiredTrue);
              }
              break;
            case 'enum':
              if(prop?.enums && prop?.enums?.length > 0)prop['value'] ? defaultValue = prop['value'] : defaultValue = prop?.enums[0]?.name || '';
              if (prop?.required) {
                validators.push(Validators.required);
              }
              break;
            case 'string':
              prop['value'] ? defaultValue = prop['value'] : defaultValue = '';;
              if (prop.required) {
                validators.push(Validators.required);
              }
              break;
            default:
              defaultValue = '';
          }
          
          group[`${activity.name}_${prop.name}`] = [defaultValue, validators];
        });
      }
    });
    
    this.workflowForm = this.fb.group(group);
  }

    // Add method to check if activity has prompts
    hasPrompts(activity: Activity): boolean {
      return !!activity.prompts && activity.prompts.length > 0;
    }
  
    // Method to handle prompt selection
    selectPrompt(incomingModal: any, prompt: Prompt, event: Event) {
      event.stopPropagation(); // Prevent activity selection
      this.openPromptModal(incomingModal, prompt);
    }
  


  private initializeSearchQNAForm() {
    const group = {};
    
    this.searchQAProperties.forEach(property => {
      let initialValue = property.value;
      let validators = [];
      
      if (property.required) {
        validators.push(Validators.required);
      }
      
      if (property.array && Array.isArray(property.value)) {
        this.customTags = property.value || [];
        initialValue = this.customTags.join(',');
        if (property.required) {
          validators.push(Validators.pattern(/^(?!\s*$).+/)); // Not empty string
        }
      }

      // Add specific validators based on type
      if (property.type === 'integer') {
        validators.push(Validators.min(0));
      }
      
      group[property.name] = [initialValue, validators];
    });

    this.searchQAPropertiesForm = this.fb.group(group);
  }

  hasErrorofSearchQNA(propertyName: string, errorType: string): boolean {
    const control = this.searchQAPropertiesForm.get(propertyName);
    return (control?.errors?.[errorType] && control.touched) ?? false;
  }

  getErrorMessageofSearchQNA(property: PropertyConfig): string {
    const control = this.searchQAPropertiesForm.get(property.name);
    
    if (!control?.errors || !control.touched) {
      return '';
    }

    if (control.errors['required']) {
      return `${property.displayName} is required`;
    }

    if (control.errors['min']) {
      return `${property.displayName} must be at least ${control.errors['min'].min}`;
    }

    if (control.errors['pattern']) {
      return `Please enter a valid ${property.displayName}`;
    }

    return '';
  }

  private initializeRangeLabels() {
    const temperatureProperty = this.searchQAProperties.find(p => p.type === 'range');
    if (temperatureProperty) {
      const { min, max, step } = temperatureProperty;
      this.rangeLabels = Array.from(
        { length: ((max - min) / step) + 1 },
        (_, i) => Number((min + i * step).toFixed(1))
      );
    }
  }

  hasProperties(activity: Activity): boolean {
    return !!activity.properties && activity.properties.length > 0;
  }

  isEnumProperty(prop: Property): prop is EnumProperty {
    return prop.type === 'enum';
  }

  isBooleanProperty(prop: Property): prop is BooleanProperty {
    return prop.type === 'boolean';
  }

  isStringProperty(prop: Property): prop is StringProperty {
    return prop.type === 'string';
  }

  getPropertyValue(activityName: string, propertyName: string): any {
    return this.workflowForm.get(`${activityName}_${propertyName}`)?.value;
  }

  isActivityEnabled(activityName: string): boolean {
    return this.workflowForm.get(`${activityName}_enabled`)?.value ?? false;
  }

  validateForm(): boolean {
    this.formErrors = {};
    let isValid = true;

    this.workflowData.activities.forEach(activity => {
      const isEnabled = this.isActivityEnabled(activity.name);
      
      // Check if required activity is enabled
      if (activity.required && !isEnabled) {
        this.formErrors[`${activity.name}_enabled`] = `${activity.title} is required`;
        isValid = false;
      }

      // Check properties if activity is enabled
      if (isEnabled && activity.properties && activity.name != 'searchQA') {
        activity.properties.forEach(prop => {
          const controlName = `${activity.name}_${prop.name}`;
          const control = this.workflowForm.get(controlName);
          
          if (prop.required && control) {
            const value = control.value;
            
            if (prop.type === 'boolean' && !value) {
              this.formErrors[controlName] = `${prop.displayName} must be checked`;
              isValid = false;
            } else if (prop.type === 'enum' && !value) {
              this.formErrors[controlName] = `Please select a ${prop.displayName}`;
              isValid = false;
            } else if (prop.type === 'string' && !value.trim()) {
              this.formErrors[controlName] = `${prop.displayName} is required`;
              isValid = false;
            }
          }
        });
      }
    });

    return isValid;
  }


  getErrorMessage(controlName: string): string {
    return this.formErrors[controlName] || '';
  }

  hasError(controlName: string): boolean {
    return !!this.formErrors[controlName];
  }
  
  ngDoCheck(): void {
    let panel = document.getElementsByClassName("ng-dropdown-panel-items");
    if (panel.length > 0) {
      let panelWidth = document.getElementById("schema-label").offsetWidth;
      panel[0].id = "panelWidth";
      document.getElementById("panelWidth").style.width = panelWidth + "px";
    }
  }

  
  onSliderChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.searchQAPropertiesForm.get('modelTemperature')?.setValue(parseFloat(value));
  }

  addTag(event: any): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();
    if (value && !this.customTags.includes(value)) {
      this.customTags.push(value);
      this.updateFormControl();
    }
    input.value = '';
  }

  removeTag(tag: string): void {
    this.customTags = this.customTags.filter((t) => t !== tag);
    this.updateFormControl();
  }

  private updateFormControl(): void {
    this.searchQAPropertiesForm.get('customSearchFields')?.setValue(this.customTags.join(','));
  }

  // ****************************************** saveWorkFlow ***************************************

  onSubmit() {
    if (this.validateForm()) {
      const formValue = this.workflowForm.value;
      const processedData = this.processFormData(formValue);
      if(this.WorkflowType == 'Search')
        {
          if (this.searchQAPropertiesForm.valid) {
            this.saveWorkFlow(processedData)
          } else {
            Object.keys(this.searchQAPropertiesForm.controls).forEach(key => {
              const control = this.searchQAPropertiesForm.get(key);
              control?.markAsTouched();
            });
            return
          }
        }
        else
        {
          this.saveWorkFlow(processedData)
        }
    } else {
      console.log('Form validation failed:', this.formErrors);
    }
  }

  private processFormData(formValue: any) {
    const result: any = {
      name: this.workflowData.name,
      title: this.workflowData.title,
      activities: []
    };

    this.workflowData.activities.forEach(activity => {
      const isEnabled = formValue[`${activity.name}_enabled`];
        const activityData: any = {
          name: activity.name,
          enabled: isEnabled,
          title: activity.title,
          required: activity.required,
          description: activity.description
        };
        if(activity?.comment)activityData.comment = activity.comment;
        if(activity?.defaultPromptName)activityData.defaultPromptName = activity.defaultPromptName;
        if(activity?.prompts)activityData.prompts = activity.prompts;
        if (activity.properties && activity.name != 'searchQA') {
          activityData.properties = activity.properties.map(prop => {
            const value = formValue[`${activity.name}_${prop.name}`];
            const propertyObject: any = {
              name: prop.name,
              displayName: prop.displayName,
              type: prop.type,
              value: value,
            };
            if (prop.hasOwnProperty('required')) {
              propertyObject.required = prop.required
          }
            if (prop.type === 'enum' && 'enums' in prop) {
              propertyObject.enums = prop.enums;
            }
            return propertyObject;
          });
        }
        else if(activity.name == 'searchQA')
          {
            activityData.properties = this.getsearchQAFormValue();
          }
        result.activities.push(activityData);
      // }
    });

    return result;
  }

  getsearchQAFormValue() {
    const formValue = this.searchQAPropertiesForm.value;
    return this.searchQAProperties.map(property => ({
      ...property,
      value: property.array 
        ? this.customTags 
        : formValue[property.name]
    }));
  }


  saveWorkFlow(processedData) {
    let body : any = {
      targetId:this.schemaProperty ? this.file?.targetId : this.file?.id,
      workflowType:this.WorkflowType,
      content:processedData
    }
    console.log("Save Workflow ==>>",body)
    this.dataService.DataService_SaveWorkFlows(body).subscribe({
      next: (res) => {
        if (res.failed) {
          this.displayAlertMessage(res.message, "failed", "danger");
        } else {
          this.displayAlertMessage("Workflow Saved successfully" ,"success","success");
        }
      },
      error: (err) => {
        this.displayAlertMessage(err, "failed", "danger");
      },
      complete: () => { },
    });
  }

  dismissModal() {
    this.modalService.dismissAll();
  }


// ****************************************** ALERT MESSAGES ***************************************


    displayAlertMessage(
      sIncommingMessage,
      sIncommingResponseType,
      sIncommingColor
    ) {
      this.bResponseAlert = true;
      this.resMessage = {
        message: sIncommingMessage,
        responseType: sIncommingResponseType,
        color: sIncommingColor,
      };
      setTimeout(() => {
        this.bResponseAlert = false;
      }, 3000);
    }
    openPromptModal(incomingModal,activity){
     this.file['activityName']=activity?.name
     this.modalRef= this.modalService.open(incomingModal, {
        centered: true,
        backdrop: "static",
        size: "fullscreen",
      })
    }
    onClose() {
      if (this.modalRef) {
        this.modalRef.close();
        this.modalRef = null; // Clear the reference after closing
      }
    }
    selectActivity(activity)
    {
      this.selectedActivity = activity.name;
    }



}
