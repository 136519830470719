import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadImageService } from 'src/app/services/upload-image.service';
@Component({
  selector: 'app-navigation-modal',
  templateUrl: './navigation-modal.component.html',
  styleUrls: ['./navigation-modal.component.scss']
})
export class NavigationModalComponent {
  constructor(
    private dialogRef: MatDialogRef<NavigationModalComponent>,
    private uploadService: UploadImageService
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.uploadService.clearUploadStatus();
    this.dialogRef.close(true);
  }

}
