import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from "src/environments/environment";
interface ApplicationAccess {
  [key: string]: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentDetailsService {
  constructor(private http: HttpClient) {}
  getUserApplications(body: any): Observable<any> {
    return this.http.post<any>(environment.BaseURL + '/ds/function/shared/AM_GetUserDetails', body )
      .pipe(
        map(response => {
          const applications: ApplicationAccess = {};
          
          response?.data?.applications?.forEach(app => {
            applications[app.name] = true;
          });
          let subscriptionObj = {
            applications:applications,
            hasRequested: response?.data?.hasRequested
          }
          
          return subscriptionObj;
        })
      );
  }
}
