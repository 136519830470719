<app-alert-messages *ngIf="bResponseAlert" [resMessage]="resMessage"></app-alert-messages>
<div class="modal-header">
  <h4 class="modal-title">{{file?.name}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
</div>
<div *ngIf="!noSchemaToShow && !bLoading" class="modal-body p-0" style="height: calc(100vh - 120px);overflow: auto;">
    <div class="workflow-container">
        <h2>{{ workflowData.title }}</h2>
        
        <form [formGroup]="workflowForm">
        <div class="activities-grid">
            <div *ngFor="let activity of workflowData.activities" class="activity-card" [ngClass]="activity.name == selectedActivity ? 'selected-activity' : ''" (click)="selectActivity(activity)">
            <div class="card-header">
                <h3>{{ activity.title }}</h3>
                <div class="toggle-container">
                <label class="switch" *ngIf="!activity?.required">
                    <input type="checkbox" [formControlName]="activity.name + '_enabled'" >
                    <span class="slider round"></span>
                </label>
                </div>
            </div>
    
            <div class="card-content">
                <p class="description mb-4">{{ activity.description }}</p>
                
                <small *ngIf="hasError(activity.name + '_enabled')" class="error-message">
                {{ getErrorMessage(activity.name + '_enabled') }}
                </small>
                <div class="badges">
                <span *ngIf="activity.required" class="required-badge">Required</span>
                </div>
    
             
                <div class="properties-section" 
                    *ngIf="hasProperties(activity) && isActivityEnabled(activity.name) && activity.name != 'searchQA'">
                <div *ngFor="let prop of activity.properties" class="property-item position-relative">
                  <div class="d-flex">
                    <label [for]="activity.name + '_' + prop.name" [class.marginLeft]="isBooleanProperty(prop)" class="mb-2_5 d-inline-block cursor-pointer">
                    {{ prop.displayName }}
                    <span *ngIf="prop.required" class="required-indicator">*</span>
                    </label>

                  </div>
                    
                    <select *ngIf="isEnumProperty(prop)" 
                            [id]="activity.name + '_' + prop.name"
                            [formControlName]="activity.name + '_' + prop.name"
                            class="form-control"
                            [class.error]="hasError(activity.name + '_' + prop.name)">
                    <option value="">Select {{ prop.displayName }}</option>
                    <option *ngFor="let option of prop.enums" [value]="option.name">
                        {{ option.displayName }}
                    </option>
                    </select>
    
                    <div *ngIf="isBooleanProperty(prop)" class="checkbox-wrapper position-absolute" style="top:-1px">
                    <input type="checkbox"
                            [id]="activity.name + '_' + prop.name"
                            [formControlName]="activity.name + '_' + prop.name"
                            class="form-checkbox"
                            [class.error]="hasError(activity.name + '_' + prop.name)">
                    </div>
    
                    <input *ngIf="isStringProperty(prop)" 
                        type="text"
                        [id]="activity.name + '_' + prop.name"
                        [formControlName]="activity.name + '_' + prop.name"
                        class="form-control"
                        [class.error]="hasError(activity.name + '_' + prop.name)"
                        [placeholder]="'Enter ' + prop.displayName">
    
                    <div *ngIf="hasError(activity.name + '_' + prop.name)" class="error-message">
                    {{ getErrorMessage(activity.name + '_' + prop.name) }}
                    </div>
                </div>
                </div>
            </div>
             <!-- Updated prompt section -->
             <div *ngIf="hasPrompts(activity)" class="position-absolute" style="bottom: 8px; right: 15px;">
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-secondary" 
                        id="promptDropdown" 
                        ngbDropdownToggle>
                        Prompts
                        <i class="fa-solid fa-ellipsis-vertical ml-2"></i> 
                </button>
                <div ngbDropdownMenu aria-labelledby="promptDropdown">
                  <button *ngFor="let prompt of activity.prompts" 
                          ngbDropdownItem 
                          (click)="selectPrompt(promptModal, prompt, $event)">
                    {{ prompt.displayName }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Fallback for activities with only defaultPromptName -->
            <div *ngIf="!hasPrompts(activity) && activity?.defaultPromptName" 
                class="position-absolute" style="bottom: 8px; right: 15px;">
              <button class="btn btn-outline-secondary" (click)="openPromptModal(promptModal, activity)">
                <i class="fas fa-pen-to-square mr-2"></i> Edit Prompt
              </button>
            </div>
          </div>
          
        </div>
        <div *ngIf="this.workflowForm.get('searchQA_enabled')?.value && !bLoading" class="card shadow-sm p-4 prompt-card">
          <div class="d-flex justify-content-start align-items-center">

          </div>
          <div class="card-body">
            <form [formGroup]="searchQAPropertiesForm" (ngSubmit)="onSubmit()">
              <div class="row mb-4">
                <ng-container *ngFor="let property of searchQAProperties">
                  
                  <!-- Boolean Input -->
                  <div *ngIf="property.type === 'boolean'" class="col-md-12">
                    <div class="form-check mb-3">
                      <input 
                        type="checkbox" 
                        [id]="property.name"
                        class="form-check-input"
                        [formControlName]="property.name"
                        style="margin-top: 6px;"
                      />
                      <label [for]="property.name" class="form-check-label">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required')" class="text-danger mt-1">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Number Input -->
                  <div *ngIf="property.type === 'integer' || (property.type === 'string' && !property.array && property.uiComponent != 'taglist')" class="col-md-4">
                    <div class="mb-3">
                      <label [for]="property.name" class="form-label">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <input 
                        [type]="property.type === 'integer' ? 'number' : 'text'" 
                        [id]="property.name"
                        class="form-control"
                        [formControlName]="property.name"
                        min="0"
                        [ngClass]="{'is-invalid': hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'min')}"
                      />
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'min')" class="invalid-feedback">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Radio Input -->
                  <div *ngIf="property.type === 'enum' && property.uiComponent === 'radio'" class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <div class="row">
                        <div *ngFor="let option of property.enums" class="col-md-4">
                          <label [for]="option.name" class="custom-radio">
                            <input 
                              type="radio"
                              [id]="option.name"
                              [formControlName]="property.name"
                              [value]="option.name"
                              class=""
                            />
                            <span class="">{{ option.displayName }}</span>
                          </label>
                        </div>
                      </div>
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required')" class="text-danger mt-1">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Range Slider -->
                  <div *ngIf="property.type === 'range' && property.uiComponent === 'slider'" class="col-md-10">
                    <div class="">
                      <label [for]="property.name" class="form-label mb-2">
                        {{ property.displayName }}
                        <span *ngIf="property.required" class="text-danger">*</span>
                      </label>
                      <div class="position-relative my-2">
                        <div class="d-flex justify-content-between position-absolute w-100" style="top: -20px;">
                          <span *ngFor="let value of rangeLabels" class="text-center" style="width: 10%; font-size: 0.8rem;">
                            {{ value }}
                          </span>
                        </div>
                        <div class="d-flex align-items-center">
                          <input 
                            type="range"
                            [id]="property.name"
                            class="form-range"
                            [formControlName]="property.name"
                            [min]="property.min"
                            [max]="property.max"
                            [step]="property.step"
                            (input)="onSliderChange($event)"
                          />
                        </div>
                      </div>
                      <span class="fw-bold mt-2">{{ searchQAPropertiesForm.get(property.name)?.value }}</span>
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required')" class="text-danger mt-1">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                  <!-- Tag List -->
                  <div *ngIf="property.type === 'string' && property.array && property.uiComponent === 'taglist'" class="col-md-12 mt-3">
                    <label [for]="property.name" class="form-label">
                      {{ property.displayName }}
                      <span *ngIf="property.required" class="text-danger">*</span>
                    </label>
                    <div class="tags-input-container">
                      <span *ngFor="let tag of customTags" class="badge bg-primary me-2">
                        <span style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                          {{ tag }}
                        </span>
                        <button 
                          type="button" 
                          class="btn-close btn-close-white btn-sm"
                          aria-label="Remove"
                          (click)="removeTag(tag)">
                        </button>
                      </span>
                      <input 
                        type="text"
                        [id]="property.name"
                        class="form-control"
                        placeholder="Enter a tag and press Enter"
                        (keyup.enter)="addTag($event)"
                        [ngClass]="{'is-invalid': hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'pattern')}"
                      />
                      <small *ngIf="hasErrorofSearchQNA(property.name, 'required') || hasErrorofSearchQNA(property.name, 'pattern')" class="invalid-feedback">
                        {{ getErrorMessageofSearchQNA(property) }}
                      </small>
                    </div>
                  </div>
        
                </ng-container>
              </div>
            </form>
          </div>
        </div>
        </form>
    </div>
</div>
<div *ngIf="bLoading" class="d-flex justify-content-center p-4  align-items-center loader-position ">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div *ngIf="noSchemaToShow && !bLoading" class="d-flex align-items-center justify-content-center p-4">
  <small class="heading">No WorkFlow Found!</small>
</div>

<div *ngIf="!noSchemaToShow && !bLoading" class="modal-footer">
    <div class="row styling-footer">
      <div class="col-6">
      </div>
      <div class="p-0 col-6 text-end">
        <button type="button" class="btn btn-outline-secondary" (click)="dismissModal()">Cancel</button>
        <button type="submit" class="btn btn-primary ml-2" (click)="onSubmit()">Save Workflow</button>
      </div>
    </div>
</div>

<ng-template #promptModal>
<app-edit-prompts [file]="file"  [completeWorkFlowList]="completeWorkFlowList"   (closeModal)="onClose()"></app-edit-prompts>
</ng-template>