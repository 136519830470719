import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { cDataService } from 'src/app/services/data.service';
import { cSessionService } from 'src/app/services/session.service';
import { SidebarService } from 'src/app/shared/sidebar/sidebar.service';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.scss'],
})
export class CloudComponent implements OnInit {
  a_t_nonce : string;
  bDisplayAjaxLoginFields: boolean = true;
  bDisplayErrorMessage : boolean = false;
  sUserRole : string;
  msg: string = "Authetication Failed.";
  oProjectConfigurations : any = {title : '',logo : '', background : ''};
  bLoading : boolean = false;
  sResponseMessage : string = "";
  bDisplayErrorBlock: boolean;
  resMessage: { message: any; responseType: any; color: any; };
  defaultAppConfig = {
    "menus" : [
       { "routerLink": "/datasource", "label": "Data Source"},
       { "routerLink": "/queries", "label": "Queries"},
       { "routerLink": "/function","label": "Functions"},
       { "routerLink": "/schedules", "label": "Schedules"},
       { "routerLink": "/apiplayground", "label": "API Playground"},
       { "routerLink": "/metadata", "label": "Metadata Files"},
       { "routerLink": "", "label": "Separator"},
       { "routerLink": "/apps/task-history", "label": "Tasks History"},
       { "routerLink": "/logs", "label": "Logs"},
       { "routerLink": "", "label": "Separator"},
        { "routerLink": "/doc-manager", "label": "Trillo Doc AI"},
       { "routerLink": "/filemanager", "label": "File Manager"},
       { "routerLink": "/datamanager", "label": "Data Browser"},
       { "routerLink": "", "label": "Separator"},
       { "routerLink": "/settings", "label": "Settings"},
       { "routerLink": "/groups", "label": "Groups"},
       { "routerLink": "/usermanager", "label": "Admin" },
       { "routerLink": "/team-members", "label": "Team Members"}
    ]
   };
  constructor(private oActivatedRoute : ActivatedRoute, private oAuthService : AuthService,private router : Router,private oSessionService : cSessionService, private dataService : cDataService,private oMenuService : SidebarService) {
    this.oActivatedRoute.queryParams.subscribe(params => {
      this.a_t_nonce = params['a_t_nonce'];
      console.log("Query Params ==>>>>",this.a_t_nonce);
      if(this.a_t_nonce == undefined)
        {
          this.sResponseMessage = "Google Sign In failed, need a-t-nonce token for further sign-in process.";
          console.log("SResponse Message",this.sResponseMessage)
          setTimeout(() => {
            
          }, 5000);
        }
    })
   }
  ngOnInit(): void 
  {
    if(this.a_t_nonce)
    {
      this.bLoading = true;
      this.sResponseMessage = "";
      this.oAuthService.AuthService_GetATNonce(this.a_t_nonce).subscribe(result => {
        console.log("Get Token ==.",result);
        if(result.status == 'failed')
        {
          this.bLoading = false;
          this.sResponseMessage = result.message;
        }
        else
        {
          this.bLoading = false;
          console.log("LoginComponent_AJAXLogin : ==> ", result)
          this.oSessionService.SessionService_SetAccessToken(result.accessToken);
          this.oSessionService.SessionService_SetUserDetails(result.user)
          this.LoginComponent_GetConfigFile();
          this.dataService.getUserDetails.next(result.user.role)
          // this.LoginComponent_GetConfigurations();
        }
      })
    }
  }
  NotFoundComponent_PreviousPath()
  {
    this.router.navigate(['/auth/login']);
  }
  LoginComponent_GetConfigFile()
  {
    this.dataService.DataService_GetConfigFIle().subscribe({
      next: (res)=>{
        if(res.failed)
        {
          console.log("failed");
          this.LoginComponent_DisplayAlertMessage(res.message,'failed','danger');
        }
        else
        {
          let appConfigHaveMenus : boolean = this.LoginComponent_IsAppConfigHaveMenus(res);
          if (!appConfigHaveMenus) {
            res.menus = this.defaultAppConfig.menus;
          }
          const oUserDetails = this.oSessionService.SessionService_GetUserDetails();
          this.LoginComponent_HandleSideBarItems(res,oUserDetails);
          this.oSessionService.SessionService_SetApplicationSideBarConfigurationFile(res);
          if(res.defaultRoute)
          {
            this.router.navigate([res.defaultRoute]);
          }
          else
          {
            this.router.navigate([this.LoginComponent_ReturnRedirectionURL()]);
          }
        }
      },
      error: (err)=>{
        console.log("Error==>",err);
      }
    })
  }
  LoginComponent_IsAppConfigHaveMenus(oIncomingObject)
  {
    /* for (let prop in oIncomingObject) {
      if (oIncomingObject.hasOwnProperty(prop)) {
        return false;
      }
    } */
    return !!oIncomingObject.menus;
  }
  LoginComponent_HandleSideBarItems(res,oUserDetails)
  {
    if (res.env === "saas") {
      for (let i = 0; i < res.menus.length; i++) {
        if ((res.menus[i].label === "Admin" || res.menus[i].label === "Tasks History") && oUserDetails.tenantId != '1') {
            res.menus.splice(i, 1);
            // break;
        }
      }
    }
    if (oUserDetails.role !== "admin"  ) {
      const itemsToRemove = ["Admin", "Team Members", "Tasks History"];
      if (res.env !== 'saas') {
        itemsToRemove.push("Metadata Files");
      }
      if (oUserDetails.role == "userSFTP" && res.menus[0].label === "Trillo File Manager" ) {
        res.menus =[
          {
              "routerLink": "/filemanager",
              "label": "Trillo File Manager"
          },
          {
              "routerLink": "/settings",
              "label": "Settings"
          },
      ]
      }
      for (const itemLabel of itemsToRemove) {
        const index = res.menus.findIndex(item => item.label === itemLabel);
        if (index !== -1) {
          res.menus.splice(index, 1);
        }
      }
    }
     // Remove consecutive separators
  for (let i = 0; i < res.menus.length - 1; i++) {
    if (res.menus[i].label === "Separator" && res.menus[i + 1].label === "Separator") {
      res.menus.splice(i, 1);
      i--; 
    }
  }  
  }
  LoginComponent_ReturnRedirectionURL()
  {
    let NavMenuItems : any = this.oSessionService.SessionService_GetApplicationSideBarConfigurationFile().menus;
    var lMenuItems = [];
    let sURL : string = '/datasource';
    this.oMenuService.items.subscribe(async menuItems =>{lMenuItems = menuItems;});
    for (let nFirstMenuItemIndex = 0; nFirstMenuItemIndex < lMenuItems.length; nFirstMenuItemIndex++) 
    {
      const oMenuItem = lMenuItems[nFirstMenuItemIndex];
      if(oMenuItem.label === NavMenuItems[0].label)
      {
        sURL = lMenuItems[nFirstMenuItemIndex].routerLink;
        break;
      }
    }
    return sURL;
  }

     // -------------------- ALERT MESSAGES --------------------
     LoginComponent_DisplayAlertMessage(sIncommingMessage, sIncommingResponseType,sIncommingColor) {
      this.bDisplayErrorBlock = true
      this.resMessage = 
      {
        message: sIncommingMessage,
        responseType : sIncommingResponseType,
        color :  sIncommingColor
      };
  
      setTimeout(() => { this.bDisplayErrorBlock = false; }, 3000);
    }
}
