

<app-alert-messages *ngIf="bResponseAlert" [resMessage]="resMessage"></app-alert-messages>

   <div class="modal-header d-flex justify-content-between">
    <div>
        <h4 class="modal-title">{{file?.name}}</h4>
    </div>
      
        <div class="d-flex justify-content-between">
            <!-- <button class="btn btn-outline-secondary btn-sm me-2" (click)="onClose()">Close</button> -->
            <button class="btn btn-primary me-2 btn-sm" (click)="activatePrompt()">Activate</button>
            <button class="btn btn-primary me-2 btn-sm" (click)="savePrompts('save')">Save</button>
            <button class="btn btn-primary me-2 btn-sm" (click)="savePrompts('saveNew')">Save as New</button>
            <button type="button" class="btn-close  mt-0" aria-label="Close" (click)="onClose()"></button>
        </div>
   
    </div>

    <div class="left-part main-sidebar ">
        <div style="height: calc(100vh - 60px)">
            <as-split unit="pixel" [gutterSize]="3" direction="horizontal">
                <as-split-area [size]="250" [minSize]="250" [maxSize]="700">
                    <aside>
                        <div class="sidebar">
                            <div
                                class="d-flex justify-content-between query-header border-bottom flex-wrap align-items-center">
                                <h6 class="mb-0 ms-2 heading">Prompts</h6>

                            </div>

                            <div class="align-left">
                                <div class="border-bottom"></div>
                                <div  *ngIf="!bLoading && promptsList?.length > 0">

                                    <div #treeViewContainer class="list-height" >
                                        <div *ngFor="let item of promptsList; index as i" [id]="item.id"
                                            class="folder-item">
                                            <div>
                                                <ul class="border-bottom">
                                                    <div>
                                                        <div class="d-flex node align-items-center py-2 px-2"
                                                            [class.selected]="selectedRowIndex == i"
                                                            (click)="getPromptData(item, i)">
                                                            <span class="mx-1"></span>
                                                            <span class="cursor-pointer d-flex align-items-center gap-10">
                                                                <span class="d-inline-block sub-heading" 
                                                                      style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                                                                      [attr.title]="(item?.displayName ? item?.displayName : item?.name + '(' + item?.version + ')')">
                                                                    {{ item?.displayName ? item?.displayName : item?.name + '(' + item?.version + ')' }}
                                                                </span>
                                                                <i *ngIf="item?.active" class="fa-light fa-circle-check font-13"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
    
                                        </div>
    
                                    </div>
                                </div>

                                <div *ngIf="!bLoading && promptsList?.length == 0" class="notes-list sidebar-tree-view mt-2">
                                    <ul class="d-flex align-items-center justify-content-center">
                                        <li>
                                            <span class="text-black-0 heading"> No Prompts Found!</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </aside>
                </as-split-area>
                <as-split-area>
                    <!-- <main class="tabs-section"> -->
                        <!-- <div class="row bg-white border-bottom d-flex justify-content-between align-items-center g-0">
                            <div class="col-md-12">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs border-0">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>JSON</a>
                                    </li>
                                </ul>
                            </div>

                        </div> -->
                    <!-- </main> -->
                    <div class="position-relative parent-codemirror code-toolbar">
                        <ngx-codemirror #codemirror id="codemirror" name="html" [options]="codeMirrorOptions"
                            [(ngModel)]="json" [autoFocus]="true" >
                        </ngx-codemirror>

                    </div>

                </as-split-area>
            </as-split>
        </div>
    </div>

