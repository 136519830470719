import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { canActivateTeam } from './guards/auth.guard';
import { AuthGuard } from './guards/redirectlogin.guard';
import { canActivateNavItem } from './guards/configuration.guard';
import { CloudComponent } from './modules/cloud/cloud.component';
import { NavigationGuard } from './guards/navigational.guard';

const routes: Routes = [
  {
		path: 'cloud/auth',
		component : CloudComponent
	},
  {
    path: '',
    component: BlankComponent,
    children:
    [
      { path:'' , redirectTo:'/auth/login' , pathMatch:'full'},
      {
        path:'auth',
        loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      // {
      //   path:'dashboard',
      //   loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      // }
      {
        path:'Download',
        loadChildren: () => import('./modules/sharedFileLink/sharedfilelink.module').then(m => m.SharedfilelinkModule)
      },
      {
        path:'filesharing/Upload',
        loadChildren: () => import('./modules/uploadFileLink/uploadfilelink.module').then(m => m.UploadfilelinkModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children:
    [
      {
        path:'app-generation', canActivate:[canActivateTeam,canActivateNavItem,], canDeactivate: [NavigationGuard],
        loadChildren: () => import('./modules/app-generation/app-generation.module').then(m => m.AppGenerationModule)
      },
      {
        path:'filemanager', canActivate:[canActivateTeam,canActivateNavItem,], canDeactivate: [NavigationGuard],
        loadChildren: () => import('./modules/file-manager/file-manager.module').then(m => m.FileManagerRoutingModule)
      },
      {
        path:'usermanager', canActivate:[canActivateTeam,AuthGuard,canActivateNavItem],
        loadChildren:()=> import('./modules/usermanager/usermanager.module').then(module => module.UsermanagerModule)
      },
      {
        path:'datamanager', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/datamanager/datamanager.module').then(module => module.DatamanagerModule)
      },
      {
        path:'dashboard', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path:'settings', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/settings/settings.module').then(module => module.SettingsModule)
      },
      {
        path:'datasource', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/data-source/data-source.module').then(module => module.DataSourceModule)
      },
      {
        path:'dictionary', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/data-dictionary/data-dictionary.module').then(module => module.DataDictionaryModule)
      },
      {
        path:'apps', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/tasks/tasks.module').then(module => module.TasksModule)
      },
      {
        path:'queries', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/queries/queries.module').then(module => module.QueriesModule)
      },
      {
        path:'logs', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/logs/logs.module').then(module => module.LogsModule)
      },
      {
        path:'bigquery', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/big-query/big-query.module').then(module => module.BigQueryModule)
      },
      {
        path:'function', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/function/function.module').then(module => module.FunctionModule)
      },
      {
        path:'schedules', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/schedules/schedules.module').then(module => module.SchedulesModule)
      },
      {
        path:'metadata', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/metadata/metadata.module').then(module => module.MetaDataModule)
      },
      {
        path:'manage-backups', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/git/git.module').then(module => module.GitModule)
      },
      {
        path:'groups', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/groups/groups.module').then(module => module.GroupsModule)
      },
      {
        path:'team-members', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/team-members/team-member.module').then(module => module.TeamMemberModule)
      },
      {
        path:'doc-manager', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/doc-manager/doc-manager.module').then(module => module.DocManagerModule)
      },
      {
        path:'enablefunction', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/enableFunction/enable-function.module').then(module => module.EnableFunctionModule)
      },
      {
        path:'enableschedules', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/enableSchedule/enable-schedule.module').then(module => module.EnableScheduleModule)
      },
      {
        path:'apiplayground', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/api-playground/api-playground.module').then(module => module.ApiPlaygroundModule)
      },
      {
        path:'applications', canActivate:[canActivateTeam,canActivateNavItem],
        loadChildren:()=> import('./modules/applications/applications.module').then(module => module.ApplicationsModule)
      }
    ]
  },
  {
    path: "**",
    redirectTo: "auth/not-found",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
