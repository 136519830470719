import {HttpClient,HttpBackend, HttpRequest} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpHeaders} from "@angular/common/http";
import { cSessionService } from './session.service';
import { NavigationModalComponent } from '../shared/navigation-modal/navigation-modal.component';
import { MatDialog } from '@angular/material/dialog';

let accessToken = localStorage.getItem(environment.AccessToken);
let httpOptions = {
  headers: new HttpHeaders({
    'accept':'application/json',
    'x-app-name':'main',
    'x-org-name':environment.OrgName,
    'content-type':'application/json',
    'Authorization': 'Bearer ' + accessToken
  })
};
@Injectable({
  providedIn: 'root'
})

export class UploadImageService
{
  constructor(private http: HttpClient,private oSessionService: cSessionService,private handler: HttpBackend,private dialog: MatDialog,){this.http = new HttpClient(handler);}

  private isUploadingSubject = new BehaviorSubject<boolean>(this.getUploadStatus());
  isUploading$ = this.isUploadingSubject.asObservable();

  setUploading(status: boolean) {
    this.isUploadingSubject.next(status);
    sessionStorage.setItem('isUploading', JSON.stringify(status));
  }

  getUploadStatus(): boolean {
    return JSON.parse(sessionStorage.getItem('isUploading') || 'false');
  }

  clearUploadStatus() {
    this.setUploading(false);
    sessionStorage.removeItem('isUploading');
  }



  UploadImageService_UploadImage(formData: any): Observable<any>
  {
    return this.http.post('https://file.io', formData, {reportProgress: true, observe: 'events'});
  }

  UploadImageService_RetrieveSignedUrl(body: any, isDoc : boolean): Observable<any>
  {
    let httpOptionsWithAccessToken: any = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'accept': 'application/json',
        'x-app-name': 'main',
        'x-org-name': environment.OrgName,
        'Authorization': 'Bearer ' + this.oSessionService.SessionService_GetAccessToken()
      })
    }
    let path = isDoc ? '/docsvc/cloudstorage/folder/retrieveUploadSignedUrl' : '/foldersvc/cloudstorage/folder/retieveSignedUrl';
    return this.http.post(environment.BaseURL + path, body, httpOptionsWithAccessToken);
  }

  UploadImageService_UploadFileOnSignedUrl(body: any, signedUrl): Observable<any>
  {
    // return this.http.put(signedUrl, body);
    const req = new HttpRequest('PUT', signedUrl, body, {
      reportProgress: true,
    });

    return this.http.request(req);
  }
  UploadImageService_UploadFileOnSignedUrlFileManager(body: any, signedUrl): Observable<any>
  {
    return this.http.put(signedUrl, body);
    
  }

  UploadImageService_SaveFileObject(body: any, isDoc : boolean): Observable<any>
  {
    let path = isDoc ? '/docsvc/cloudstorage/folder/saveDocObject' : '/foldersvc/cloudstorage/folder/saveFileObject';
    return this.http.post(environment.BaseURL + path, body, httpOptions);
  }

  UploadImageService_SaveFile(body: any): Observable<any>
  {
    return this.http.post(environment.BaseURL + '/ds/save/File', body, httpOptions);
  }
  UploadImageService_SaveFileObjectWithTask(body: any, isDoc : boolean): Observable<any> {
    let httpOptionsWithAccessToken: any = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'accept': 'application/json',
        'x-app-name': 'main',
        'x-org-name': environment.OrgName,
        'Authorization': 'Bearer ' + this.oSessionService.SessionService_GetAccessToken()
      })
    }

    let path = isDoc ? '/docsvc/cloudstorage/folder/saveDocObject' : '/foldersvc/cloudstorage/folder/saveFileObject';
    return this.http.post(environment.BaseURL + path, body, httpOptionsWithAccessToken);
  }
}

