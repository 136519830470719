import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgModel, Validators } from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import {
  NgbDropdown,
  NgbDropdownConfig,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { cDataService } from "src/app/services/data.service";
import { MustMatch } from "src/app/services/must-match.validator";
import { cSessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
import { UiService } from "src/app/services/ui.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from 'rxjs';
import { filter } from "rxjs";
import { PaymentDetailsService } from "src/app/services/payment-details.service";
import { AuthService } from "src/app/services/auth.service";
interface TenantInformation {
  tenantId: string;
  tenantName: string;
}
interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Output() onToggleSidenav: EventEmitter<SideNavToggle> = new EventEmitter();
  @Input() isSideNavCollapsed: any;
  @Output() emitSidenavCollapsed = new EventEmitter();
  organizationList: any[] = [];
  bShowIcon = false;
  bShowSettingDropdown = false;
  UserDetails: any;
  bShowSearchIcon = false;
  searchResult: string = "";
  searchDocumentResult: string = "";
  showTrashLabel: boolean = false;
  bDisplayErrorBlock: boolean = false;
  changePasswordForm: FormGroup;
  changeNameForm: FormGroup;
  loginTenantInformation: TenantInformation = { tenantId: "", tenantName: "" };
  resMessage: { message: any; responseType: any; color: any };
  closesidebar: any;
  bSplitArea: boolean;
  bDisplayGlobalSearches: boolean = false;
  saasEnvButtons: boolean = false;
  selectedDocumentFolderId: string = "";
  // searchWithQNA : boolean = false;
  versionInformation: any;
  hasRequested:number | string;
  public logo: any;
  showLogo: boolean = false;
  showEllipses: boolean = false;
  collapsed: boolean = false;
  screenWidth: number;
  userDetails: any;
  environment: any;
  lDateList: any[] = [
    { name: "Any Time", value: "anyTime" },
    { name: "Today", value: "today" },
    { name: "Yesterday", value: "yesterday" },
    { name: "Last 7 Days", value: "last7Days" },
    { name: "Last 30 Days", value: "last30Days" },
    { name: "Last 90 Days", value: "last90Days" },
    { name: "Custom..", value: "custom" },
  ];
  bToggleFilterDropdown: boolean = false;
  viewBtn: boolean;
  docState: any;
  matching: any;
  activeBrowseDocs: boolean = false;
  configFileLogo: any;
  conf
  menuItems: any;
  isSchemasRoute = false;
  selectedApp:string;
  applicationsList:any;
  currentUrl = window.location.href;
  applications: { [key: string]: boolean } = {};
  config: any;
  orgLoader:boolean=false;
  orgsCode:string = '';
  search = 'Search';
  file : any = {
    id : '-1'
  }
 
  documentType: any;
  docType: any = [
    { type: "All", name: "All Documents" },
    { type: "text", name: "Text, Search & QA" },
    { type: "data", name: "Data Extraction, Search & QA" },
    { type: "code", name: "Code Generation" }
  ];

  constructor(
    private fb: FormBuilder,
    config: NgbDropdownConfig,
    private _location: Location,
    private cSessionsService: cSessionService,
    private sUIService: UiService,
    private oRoute: Router,
    private oFormBuilder: FormBuilder,
    private oDataService: cDataService,
    private oSessionService: cSessionService,
    private oModalService: NgbModal,
    private paymentDetails : PaymentDetailsService,
    private authService : AuthService,
    private route: ActivatedRoute,
    private router: Router

  ) {
    this.environment = environment;
    config.autoClose = true;
  }
  stopPropagation(event: Event): void {
    event.stopPropagation();
  }
  showButton: boolean = false;

  ngOnInit(): void {
    this.organizationList = this.cSessionsService.SessionService_GetOrganizations() ?? [];
    this.orgsCode = this.cSessionsService.SessionService_GetOrgsCode();
    this.UserDetails = this.oSessionService.SessionService_GetUserDetails();
    this.menuItems= this.oSessionService.SessionService_GetApplicationSideBarConfigurationFile();
    let searchQuery = JSON.parse(localStorage.getItem("searchQuery"));
    if (searchQuery) {
      this.searchDocumentResult = searchQuery.search;
    }
    if(this.currentUrl.includes('localhost:4400') || this.currentUrl.includes('saas-dev'))
    {
        this.getApplicationAccess();
    }
    this.cHeaderComponent_GetLogo();
    this.saasEnvButtons = this.oSessionService.SessionService_GetApplicationSideBarConfigurationFile().env == "saas" ? true : false;
    this.oDataService.bDisplyDropdown.subscribe((res) => {
      this.bShowIcon = res;
    });

    this.oDataService.headerViewBtn.subscribe((res) => {
      this.viewBtn = res;
      this.activeBrowseDocs = res;
    });

    this.oDataService.bDisplaySettingDropdown.subscribe((res) => {
      this.bShowSettingDropdown = res;
    });

    this.oDataService.bShowHeaderSearch.subscribe((res) => {
      this.bShowSearchIcon = res;
    });
    this.oDataService.trash.subscribe((value) => {
      this.showTrashLabel = value;
    });
 

    this.oDataService.closeSidebar.subscribe((res) => {
      this.closesidebar = res;
    });

    this.oDataService.globalSearch.subscribe((res) => {
      this.bDisplayGlobalSearches = res.searchBoolan;

      this.selectedDocumentFolderId = res.folderId ?? "";
    });
    this.oDataService.resetSearch.subscribe((res) => {
      if (res) {
        this.searchDocumentResult = "";
      }
    });
    this.oDataService.bDisplayDocEllipses.subscribe((res) => {
      this.showEllipses = res;
    });

    this.oDataService.populateGlobalSeacrhField.subscribe((res) => {
      if (res) {
        this.searchDocumentResult = res.searchPhrase;
      }
    });
    this.oDataService.updateHeaderLogo.subscribe((res) => {
      if (res != "") {
        this.logo = res;
      }
    });

    this.oDataService.headerViewBtn.subscribe((res) => {
      this.viewBtn = res;
    });
        this.route.queryParams.subscribe((params) => {
      this.documentType = params['documentType'];
      this.documentType = this.documentType === undefined  ? 'All' : this.documentType;      
    });
    setTimeout(() => this.checkRoute(), 100); 

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.checkRoute());
  }

  private checkRoute(): void {
    // Extract only the base path, ignoring query parameters
    const urlWithoutQuery = this.router.url.split('?')[0]; 
    this.showButton = urlWithoutQuery === '/app-generation/application-manager';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userDetails = this.cSessionsService.SessionService_GetUserDetails();
    if (this.userDetails?.role == "admin" || this.userDetails?.role == "editor")
      this.collapsed = this.isSideNavCollapsed;
  }
  preventInput(event: KeyboardEvent): void {
    event.preventDefault(); // Prevents typing in the input field
  }

  getApplicationAccess()
  {
    let body = {
      userId : this.userDetails?.userId,
      tenantId:Number(this.userDetails?.tenantId)
    }
    this.paymentDetails.getUserApplications(body)
    .subscribe({
      next: (subscription) => {
        console.log(subscription)
        this.applications = subscription.applications;
        this.hasRequested = subscription.hasRequested;
      },
      error: (error) => {
        console.error('Error fetching user applications:', error);
      }
    });
  }

  onSearchDocumentResultChange(searchString) {
    if (searchString == "") {
      localStorage.removeItem("searchQuery");
    } else {
      let queryParams = {
        search: searchString,
        qna: searchString.includes("?"),
        folderId: this.selectedDocumentFolderId,
      };
      localStorage.setItem("searchQuery", JSON.stringify(queryParams));
    }
  }
  cHeaderComponent_GetLogo() {
    this.oDataService.DataService_GetLoginConfigLogo().subscribe({
      next: (result) => {
        this.showLogo = true;
        if (result.status === "failed") {
        } else {
          this.configFileLogo=result?.data?.logo;
          this.logo =
            result.data?.logo?.logo ??
            "../../../assets/images/logo-text-dark.png";  
        }
      },
      error: (error) => {
        this.showLogo = true;
        this.logo = "../../../assets/images/logo-text-dark.png";
      },
      complete: () => {},
    });
  }
  HeaderComponent_OpenEditprofile(editProfile) {
    this.oModalService.open(editProfile, {
      centered: true,
      backdrop: "static",
      size: "md",
    });
    this.HeaderComponent_InitializeChangeNameForm();
  }
  HeaderComponent_ChangePassword(changePassword) {
    this.oModalService.open(changePassword, {
      centered: true,
      backdrop: "static",
      size: "md",
    });
    this.HeaderComponent_InitializeChangePasswordForm();
  }
  HeaderComponent_DismissModal() {
    this.changePasswordForm?.reset();
    this, this.changeNameForm?.reset();
    this.oModalService.dismissAll();
  }
  HeaderComponent_Logout() {
    const storedVersion = localStorage.getItem('app_version');
    const lsConfig = this.oSessionService.SessionService_GetApplicationSideBarConfigurationFile();
    this.oDataService.oGetFolders.next("");
    this.oDataService.oGetDocFolders.next("");
    localStorage.clear();
    localStorage.setItem('app_version',storedVersion);
    this.oSessionService.SessionService_SetApplicationSideBarConfigurationFile(lsConfig);


    setTimeout(() => localStorage.removeItem("DataSourceTabs"), 500);
    this.oDataService.globalSearch.next({ folderId: "", searchBoolan: false });
    this.searchDocumentResult = "";
    this.oRoute.navigate(["/auth/login"]);
    localStorage?.removeItem("docState");
    localStorage?.removeItem("v1Check");
    let res = {
      searchPhrase: "",
      qNa: false,
    };
    this.oDataService.populateGlobalSeacrhField.next(res);
    this.oDataService.getUserDetails.next(null);
  }
  HeaderComponent_hideHeaderSearch() {
    this.oDataService.globalSearch.next({ folderId: "", searchBoolan: false });
    localStorage.removeItem("searchQuery");
    this.searchDocumentResult = "";
    this.collapsed = false;
    this.onToggleSidenav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }
  HeaderComponent_NavigateToServices() {
    this.oRoute.navigate(["/intro"]);
  }
  HeaderComponent_FilterData() {
    if (this.searchResult != "") {
      this.oRoute.navigate(["/dictionary/search-results"], {
        queryParams: { searchPhrase: this.searchResult },
      });
    }
  }
  HeaderComponent_InitializeChangePasswordForm() {
    const oPasswordRejex = /^.{8,}$/;
    this.changePasswordForm = this.oFormBuilder.group(
      {
        userId: [this.UserDetails.userId, Validators.required],
        password: [
          "",
          [Validators.required, Validators.pattern(oPasswordRejex)],
        ],
        rptPassword: ["", Validators.required],
        oldPassword: [
          "",
          [Validators.required, Validators.pattern(oPasswordRejex)],
        ],
      },
      { validator: MustMatch("password", "rptPassword") }
    );
  }
  HeaderComponent_InitializeChangeNameForm() {
    let oTextRejex = /^(?! )(.*)$/;
    this.changeNameForm = this.oFormBuilder.group({
      userId: [this.UserDetails.userId, [Validators.required]],
      firstname: [
        this.UserDetails.firstName,
        [Validators.required, Validators.pattern(oTextRejex)],
      ],
      lastname: [
        this.UserDetails.lastName,
        [Validators.required, Validators.pattern(oTextRejex)],
      ],
    });
  }
  HeaderComponent_changePassword() {
    if (this.changePasswordForm.valid) {
      let body = this.UserDetails;
      body.oldPassword = this.changePasswordForm.controls["oldPassword"].value;
      body.password = this.changePasswordForm.controls["password"].value;
      body.rptPassword = this.changePasswordForm.controls["rptPassword"].value;
      this.oDataService.DataService_ChangePassword(body).subscribe({
        next: (result) => {
          if (result.failed) {
            if(result.message == "Unauthorized"){
              this.HeaderComponent_displayAlertMessage( "Current password is incorrect", "failed", "danger" );
            } 
            else if(result.message !== "Unauthorized") {
              this.HeaderComponent_displayAlertMessage( result.detailMessage, "failed", "danger" );
            }
          } else {
            this.oModalService.dismissAll();
            this.changePasswordForm.reset();
            this.HeaderComponent_displayAlertMessage(
              "Success",
              "success",
              "success"
            );
            setTimeout(() => {
              this.HeaderComponent_Logout();
            }, 2000);
          }
        },
        error: (error) => {
          this.HeaderComponent_displayAlertMessage(
            error.message == "null" ? "error" : error.message,
            "failed",
            "danger"
          );
        },
        complete: () => {},
      });
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }
  HeaderComponent_changeName() {
    if (this.changeNameForm.valid) {
      let body = JSON.parse(JSON.stringify(this.UserDetails));
      body.firstName = this.changeNameForm.controls["firstname"].value;
      body.lastName = this.changeNameForm.controls["lastname"].value;
      body.fullName = body.firstName + " " + body.lastName;
      this.oDataService.DataService_EditUserProfile(body).subscribe({
        next: (result) => {
          if (result.failed) {
            if (result?.namedMessages)
              this.HeaderComponent_displayAlertMessage(
                result.namedMessages[0].message,
                "failed",
                "danger"
              );
            else {
              this.HeaderComponent_displayAlertMessage(
                result.detailMessage,
                "failed",
                "danger"
              );
            }
          } else {
            this.oModalService.dismissAll();
            this.changeNameForm.reset();
            this.HeaderComponent_displayAlertMessage(
              "Success",
              "success",
              "success"
            );
            this.UserDetails = result.props.user;
            this.oSessionService.SessionService_SetUserDetails(
              result.props.user
            );
          }
        },
        error: (error) => {
          this.HeaderComponent_displayAlertMessage(
            error.message,
            "failed",
            "danger"
          );
        },
        complete: () => {},
      });
    } else {
      this.changeNameForm.markAllAsTouched();
    }
  }
  // -------------------- ALERT MESSAGES --------------------
  HeaderComponent_displayAlertMessage(
    sIncommingMessage,
    sIncommingResponseType,
    sIncommingColor
  ) {
    this.bDisplayErrorBlock = true;
    this.resMessage = {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor,
    };
    setTimeout(() => {
      this.bDisplayErrorBlock = false;
    }, 3000);
  }
  HeaderComponent_GoTopreviousPage() {
    let link = this.oRoute.url;
    const questionMarkIndex = link.indexOf("?");
    const extractedString = questionMarkIndex !== -1 ? link.substring(0, questionMarkIndex + 1) : link;
    switch (extractedString) {
      case '/doc-manager/results?':
      localStorage.removeItem('searchQuery')
      this.searchDocumentResult = ''
      this.oRoute.navigate(['/doc-manager'])
      break;
      case '/doc-manager':
        localStorage.removeItem('searchQuery')
        this.searchDocumentResult = ''
      this.oDataService.clickLogo.next(true);
      break;
      }
  }
  HeaderComponent_ToggleCollapse() {
    this.bSplitArea = !this.bSplitArea;
  }
  HeaderComponent_NavigateToSearchDashboard() {
    let queryParams = {
      search: this.searchDocumentResult,
      qna: this.searchDocumentResult.includes("?"),
      folderId: this.selectedDocumentFolderId,
      // filterForm: JSON.stringify(this.filterForm.value),
    };
    this.oDataService.showdashBoardSearch.next({ query: queryParams });
    localStorage.setItem("searchQuery", JSON.stringify(queryParams));
  }
  HeaderComponent_TenantInformation(oIncomingModal) {
    this.oDataService.DataServiceGetTenantInformation().subscribe({
      next: (result) => {
        if (result.failed) {
          this.HeaderComponent_displayAlertMessage(
            result.message,
            "failed",
            "danger"
          );
        } else {
          this.loginTenantInformation = result;
          this.oModalService.open(oIncomingModal, {
            centered: true,
            backdrop: "static",
            size: "md",
          });
        }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage(
          error.message,
          "failed",
          "danger"
        );
      },
      complete: () => {},
    });
  }
  HeaderComponent_VersionInformation(oIncomingModal) {
    this.oDataService.DataService_GetWBVersion().subscribe({
      next: (result) => {
        if (result.failed) {
          this.HeaderComponent_displayAlertMessage(
            result.message,
            "failed",
            "danger"
          );
        } else {
          this.versionInformation = this.removeSpacesInKeys(result);
          const apiDateTime = new Date(this.versionInformation.BuildDate);
          // Format the date portion
          const formattedDate = apiDateTime.toLocaleDateString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });

          // Format the time portion in 12-hour format with AM/PM
          const formattedTime = apiDateTime.toLocaleTimeString(undefined, {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true, // Use 12-hour format with AM/PM
          });
          const formattedDateTime = `${formattedDate} ${formattedTime}`;
          this.versionInformation.BuildDate = formattedDateTime;
          this.oModalService.open(oIncomingModal, {
            centered: true,
            backdrop: "static",
            size: "md",
          });
        }
      },
      error: (error) => {
        this.HeaderComponent_displayAlertMessage(
          error.message,
          "failed",
          "danger"
        );
      },
      complete: () => {},
    });
  }
  removeSpacesInKeys(obj) {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.replace(/ /g, "");
        newObj[newKey] = obj[key];
      }
    }
    return newObj;
  }
  SidebarComponent_ToggleCollapse() {
    this.collapsed = !this.collapsed;
    this.onToggleSidenav.emit({
      collapsed: this.collapsed,
      screenWidth: this.screenWidth,
    });
  }

  toggleDropdown() {
    this.bToggleFilterDropdown = !this.bToggleFilterDropdown;
  }

  navigateToApplicationUrl(){
    const user = encodeURIComponent(JSON.stringify(this.userDetails))
    let accessToken = this.oSessionService.SessionService_GetAccessToken()

      const isSubscribed =  Object.keys(this.applications).length > 0 ? true : false; 
      const targetUrl =`${this.environment.BillingApplication}?token=${accessToken}&&user=${user}&&isSubscribed=${isSubscribed}`
      window.location.href = targetUrl;
    
  }

  HeaderComponent_Organistation(changeOrganization) {
    this.oModalService.open(changeOrganization, {
      centered: true,
      backdrop: "static",
      size: "md",
    });
  }
  onOrganizationSelection(selectedOrg){
    this.orgLoader = true;
    console.log(selectedOrg);
    if (!this.orgsCode || !selectedOrg.tenantName) {
      console.error("Missing code or tenantName");
      this.HeaderComponent_displayAlertMessage("Code or tenantName is missing!", "failed", "danger");
      return;
    }
  
    const body = {
      code: this.orgsCode,
      tenantName: selectedOrg.tenantName
    };
    this.authService.AuthService_SelectTenant(body).subscribe({
      next: (result) => {
        if (result.status === 'connected') {
          this.oSessionService.SessionService_SetAccessToken(result.accessToken);
          this.oSessionService.SessionService_SetUserDetails(result.user);
          const currentUrl = window.location.origin + '/doc-manager';
          window.location.assign(currentUrl);
        } else {
          this.HeaderComponent_displayAlertMessage(result.message, "failed", "danger");
          this.orgLoader = false;
        }
      },
      error: (error) => {
        this.orgLoader = false;
        console.error("Error in selectTenant:", error);
        this.HeaderComponent_displayAlertMessage("Error to change organization", "failed", "danger");
      }
    });
  }
  
  openSchemaWorkflow(workflow:any) {
    this.file['isHeader']=true
    this.oModalService.open(workflow, {
      centered: true,
      backdrop: "static",
      size: "fullscreen",
    });
  }

  onDocTypeChange() {
    const queryParams = this.documentType !== 'All' ? { documentType: this.documentType } : {};
    this.router.navigate(['/doc-manager'], {
      queryParams: queryParams
    }).then(() => {
      window.location.reload(); 
    });
    localStorage.removeItem('DocumentManagerNestedFolder')
    localStorage.removeItem('DocumentManagerSelectedItem')

  }
  get isDocManagerRoute(): boolean {
    return this.router.url.includes('/doc-manager');
  } 
  
}
