import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { NavigationModalComponent } from '../shared/navigation-modal/navigation-modal.component';
import { UploadImageService } from '../services/upload-image.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationGuard implements CanDeactivate<any> {
  constructor(
    private dialog: MatDialog,
    private uploadService: UploadImageService
  ) {}

  canDeactivate(): Observable<boolean> {
    if (this.uploadService.getUploadStatus()) {
      return this.showModal();
    }
    return of(true); // Allow navigation if no upload is in progress
  }

  private showModal(): Observable<boolean> {
    const dialogRef = this.dialog.open(NavigationModalComponent, {
      width: '400px',
      disableClose: true,
      backdropClass: 'custom-backdrop',
      panelClass: 'custom-modal',
    });

    return dialogRef.afterClosed();
  }
}