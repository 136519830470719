import { Injectable } from '@angular/core';
import { version } from '../version';

@Injectable({
  providedIn: 'root'
})
export class CacheBusterService {
  private readonly VERSION_KEY = 'app_version';
  private readonly LAST_CHECK_KEY = 'last_version_check';
  private readonly CHECK_INTERVAL = 1000 * 60 * 0.1; // 6 seconds for testing
  private isCheckingVersion = false;
  
  constructor() {
    window.addEventListener('online', () => this.checkVersion());
  }

  async initialize(): Promise<void> {
    await this.checkVersion();
    this.setupPeriodicCheck();
  }

  private setupPeriodicCheck(): void {
    setInterval(async () => {
      if (!document.hidden) {
        await this.checkVersion();
      }
    }, this.CHECK_INTERVAL);

    document.addEventListener('visibilitychange', async () => {
      if (!document.hidden) {
        await this.checkVersion();
      }
    });
  }

  private async checkVersion(): Promise<void> {
    if (this.isCheckingVersion) {
      return;
    }

    try {
      this.isCheckingVersion = true;
      const lastCheck = localStorage.getItem(this.LAST_CHECK_KEY);
      const now = Date.now();

      if (!lastCheck || (now - parseInt(lastCheck)) > this.CHECK_INTERVAL) {
        const currentVersion = version;
        const storedVersion = localStorage.getItem(this.VERSION_KEY);
        
        localStorage.setItem(this.LAST_CHECK_KEY, now.toString());

        if (!storedVersion || storedVersion !== currentVersion) {
          console.log('Version change detected:', { current: currentVersion, stored: storedVersion });
          await this.clearCaches();
          localStorage.setItem(this.VERSION_KEY, currentVersion);
        }
      }
    } catch (error) {
      console.error('Version check failed:', error);
    } finally {
      this.isCheckingVersion = false;
    }
  }

  private async clearCaches(): Promise<void> {
    try {
      // Save important data
      const versionKey = localStorage.getItem(this.VERSION_KEY);
      
      // Clear browser caches
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      }

      // Clear storages
      localStorage.clear();
      sessionStorage.clear();
      
      // Restore version information
      localStorage.setItem(this.VERSION_KEY, versionKey || version);
      
      // Handle logout
      localStorage.setItem('authEvent', Date.now().toString());

      // Force reload without cache
      window.location.reload();
    } catch (error) {
      console.error('Failed to clear caches:', error);
      // Fallback reload if clearing fails
      window.location.reload();
    }
  }
}