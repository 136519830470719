import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class cSessionService {

  constructor() { }

  cSessionService_set(key, oData) {
    localStorage.setItem(key, JSON.stringify(oData))
  }

  cSessionService_get(key) {
    let str = localStorage.getItem(key);
    if (str) {
      return JSON.parse(str);
    }
    return {};
  }
  // Save Selected Table Name
  cSessionService_SetSelectedTableName(oData) {
    this.cSessionService_set('lsSelectedTable', oData);
  }
  cSessionService_GetSelectedTableName() {
    return this.cSessionService_get('lsSelectedTable');
  }
  cSessionService_SetSortingData(oData) {
    this.cSessionService_set('lSortingOrder', oData);
  }
  cSessionService_GetSortingData() {
    return this.cSessionService_get('lSortingOrder');

  }
  cSessionService_SetAttributesList(oData) {
    this.cSessionService_set('lsTableAttributes', oData);
  }
  cSessionService_GetAttributesList() {
    return this.cSessionService_get('lsTableAttributes');
  }

  cSessionService_SetFileManagerFolderSearchString(oData) {
    this.cSessionService_set('FileManagerFolderSearchString', oData);
  }
  cSessionService_GetFileManagerFolderSearchString() {
    return this.cSessionService_get('FileManagerFolderSearchString');
  }
  cSessionService_SetDocManagerFolderSearchString(oData) {
    this.cSessionService_set('DocManagerFolderSearchString', oData);
  }
  cSessionService_GetDocManagerFolderSearchString() {
    return this.cSessionService_get('DocManagerFolderSearchString');
  }

  cSessionService_SetFileManagerSelectedItem(oData) {
    this.cSessionService_set('FileManagerSelectedItem', oData);
  }
  cSessionService_GetFileManagerSelectedItem() {
    return this.cSessionService_get('FileManagerSelectedItem');
  }

  cSessionService_SetDocumentManagerSelectedItem(oData) {
    this.cSessionService_set('DocumentManagerSelectedItem', oData);
  }
  cSessionService_GetDocumentManagerSelectedItem() {
    return this.cSessionService_get('DocumentManagerSelectedItem');
  }

  //  File Manager Functions Starts
  SessionService_SetAccessToken(oData: any) {
    this.cSessionService_set(environment.AccessToken, oData);
  }
  SessionService_GetAccessToken() {
    return this.cSessionService_get(environment.AccessToken);
  }
  SessionService_SetUserDetails(oData: any) {
    this.cSessionService_set('lsUserDetails', oData);
  }
  SessionService_GetUserDetails() {
    return this.cSessionService_get('lsUserDetails');
  }
  SessionService_SetSate(oData: any) {
    this.cSessionService_set('lsState', oData);
  }
  SessionService_GetState() {
    return this.cSessionService_get('lsState');
  }
  // File Manager Functions Ends

  SessionService_SetBucketandDatabaseState(oData: any) {
    this.cSessionService_set('DataBaseAndBucket', oData);
  }
  SessionService_GetBucketandDatabaseState() {
    return this.cSessionService_get('DataBaseAndBucket');
  }

  // Manage Table ---- Data Source
  cSessionService_SetDataSourceMapByUid(oData) {
    this.cSessionService_set('DataSourceMapByUid', oData);
  }
  cSessionService_GetDataSourceMapByUid() {
    return this.cSessionService_get('DataSourceMapByUid');
  }
  cSessionService_SetDataSourceTabByModelClassName(oData) {
    this.cSessionService_set('DataSourceTabByModelClassName', oData);
  }
  cSessionService_GetDataSourceTabByModelClassName() {
    return this.cSessionService_get('DataSourceTabByModelClassName');
  }
  cSessionService_SetSelectedTreeNode(oData) {
    this.cSessionService_set('oSelectedDBTreeNode', oData);
  }
  cSessionService_GetSelectedTreeNode() {
    return this.cSessionService_get('oSelectedDBTreeNode');
  }

 // Related To Function Component
  cSessionService_SetFunctionMapByUid(oData) {
    this.cSessionService_set('FunctionMapByUid', oData);
  }
  cSessionService_GetFunctionMapByUid() {
    return this.cSessionService_get('FunctionMapByUid');
  }

  cSessionService_SetFunctionTabByModelClassName(oData) {
    this.cSessionService_set('FunctionTabByModelClassName', oData);
  }
  cSessionService_GetFunctionTabByModelClassName() {
    return this.cSessionService_get('FunctionTabByModelClassName');
  }

  cSessionService_SetSelectedFunctionTreeNode(oData) {
    this.cSessionService_set('oSelectedFunctionTreeNode', oData);
  }
  cSessionService_GetSelectedFunctionTreeNode() {
    return this.cSessionService_get('oSelectedFunctionTreeNode');
  }

  // metadata files
  cSessionService_SetMetaDataMapByUid(oData) {
    this.cSessionService_set('MetaDataMapByUid', oData);
  }
  cSessionService_GetMetaDataMapByUid() {
    return this.cSessionService_get('MetaDataMapByUid');
  }

  cSessionService_SetSelectedMetadataTreeNode(oData) {
    this.cSessionService_set('oSelectedMetaDataTreeNode', oData);
  }
  cSessionService_GetSelectedMetadataTreeNode() {
    return this.cSessionService_get('oSelectedMetaDataTreeNode');
  }

 
  cSessionService_SetIncludeDataSourceSystemClass(oData) {
    this.cSessionService_set('includeDataSourceSystemClass', oData);
  }
  cSessionService_GetIncludeDataSourceSystemClass() {
    return this.cSessionService_get('includeDataSourceSystemClass');
  }

  cSessionService_SetIncludeFunctionSystemClass(oData) {
    this.cSessionService_set('includeFunctionSystemClass', oData);
  }
  cSessionService_GetIncludeFunctionSystemClass() {
    return this.cSessionService_get('includeFunctionSystemClass');
  }

  // Related To Data Dictionary Component
  cSessionService_SetSelectedDataDictionaryTreeNode(oData) {
    this.cSessionService_set('oSelectedDataDictionaryNode', oData);
  }
  cSessionService_GetSelectedDataDictionaryTreeNode() {
    return this.cSessionService_get('oSelectedDataDictionaryNode');
  }

  SessionService_SetApplicationSideBarConfigurationFile(oData: any) {
    this.cSessionService_set('lsConfigFile', oData);
  }
  SessionService_GetApplicationSideBarConfigurationFile() {
    return this.cSessionService_get('lsConfigFile');
  }

  cSessionService_SetSearchResultObject(oData) {
    this.cSessionService_set('SelectedItemFromSearchResults', oData);
  }
  cSessionService_GetSearchResultObject() {
    return this.cSessionService_get('SelectedItemFromSearchResults');
  }
  cSessionService_SetDataDictionaryMapByUid(oData) {
    this.cSessionService_set('DataDictionaryMapByUid', oData);
  }
  cSessionService_GetDataDictionaryMapByUid() {
    return this.cSessionService_get('DataDictionaryMapByUid');
  }

  // Related To Schedules
  cSessionService_SetSelectedScheduleNode(oData) {
    this.cSessionService_set('oSelectedScheduleNode', oData);
  }
  cSessionService_GetSelectScheduleNode() {
    return this.cSessionService_get('oSelectedScheduleNode');
  }

    // Related To API Play Ground
    cSessionService_SetSelectedAPIplaygroundNode(oData) {
      this.cSessionService_set('oSelectedAPIplaygroundNode', oData);
    }
    cSessionService_GetSelectAPIplaygroundNode() {
      return this.cSessionService_get('oSelectedAPIplaygroundNode');
    }
  
  // Related To Queries
  cSessionService_SetSelectedQueryNode(oData) {
    this.cSessionService_set('oSelectedQueryNode', oData);
  }
  cSessionService_GetSelectQueryNode() {
    return this.cSessionService_get('oSelectedQueryNode');
  }

  //set and get current page in local storage
  SessionService_SetCurrentPage(oData) {
    localStorage.setItem('currentPage', JSON.stringify(oData));
  }
  SessionService_GetCurrentPage() {
    return JSON.parse(localStorage.getItem('currentPage'));
  }
  cSessionService_SetDocumentManagerNestedFolder(oData) {
    this.cSessionService_set('DocumentManagerNestedFolder', oData);
  }
  cSessionService_GetDocumentManagerNestedFolderItem() {
    return this.cSessionService_get('DocumentManagerNestedFolder');
  }
}
