import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BusyLoaderService } from './services/busyloader.service';
import { cDataService } from './services/data.service';
import { UiService } from './services/ui.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { cSessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'saas';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  configEnv: any

  constructor( private sessionService: cSessionService, private loaderService: BusyLoaderService, private sUIService: UiService, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private oDataService: cDataService, private oCheckIdleState: Idle, private keepalive: Keepalive, private oRouter: Router) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token'); 
    const user = urlParams.get('user');   
    
    const userDetails = user ? JSON.parse(user) : null;
      if (token) {
          this.sessionService.SessionService_SetAccessToken(token);
          this.sessionService.SessionService_SetUserDetails(userDetails);
          this.LoginComponent_GetConfigFile(true);
          this.oDataService.getUserDetails.next(userDetails.role)
          this.oDataService.switchToDocuments.next(true);
          this.oDataService.activeBrowseDocs.next(true);
          this.oRouter.navigate(['/dashboard']);
      }
    let userRole = this.sessionService.SessionService_GetUserDetails();
    let lsConfig = this.sessionService.SessionService_GetApplicationSideBarConfigurationFile();
    console.log('Environment:', lsConfig);
    if ((!lsConfig || Object.keys(lsConfig).length == 0)) {
      this.LoginComponent_GetConfigFile(false);
    }

    if (environment.Theme == 'kidm' && userRole?.role !== 'user') {
      this.renderer.addClass(document.body, environment.Theme)
    }

    this.oDataService.getUserDetails.subscribe(res => {
      if (res == 'user' && environment.Theme !== 'kidm') {
        this.renderer.addClass(document.body, 'user')
        this.renderer.removeClass(document.body, environment.Theme)

      }
      else if (res == 'user' && environment.Theme == 'kidm') {
        this.renderer.addClass(document.body, 'kidm-user');
        this.renderer.removeClass(document.body, environment.Theme);
      }

      else {
        this.renderer.removeClass(document.body, 'user')
        this.renderer.removeClass(document.body, 'kidm-user')
        this.renderer.addClass(document.body, environment.Theme)

      }
    })


    if (userRole?.role == 'user' && environment.Theme == 'kidm') {
      this.renderer.addClass(document.body, 'kidm-user');
      this.renderer.addClass(document.body, environment.Theme)
    }
    if (userRole?.role == 'user' && environment.Theme !== 'kidm') {
      this.renderer.addClass(document.body, userRole?.role)
      this.renderer.removeClass(document.body, environment.Theme)

    }


    this.changeFavicon()
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      let docState = JSON.parse(localStorage.getItem('docState'));
      if (status) {
        this.renderer.addClass(document.body, 'loading');
      }
      else {
        this.renderer.removeClass(document.body, 'loading');
      }



    });
  }
  ngOnInit(): void {
    this.oDataService.SetApplicationOnRememberCheck.subscribe(result => {
      let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
      let TimeOutForApplication: number = result;
      rememberMe == true ? TimeOutForApplication = 604800 : TimeOutForApplication = 14400;
      this.AppComponent_SetSessionExpiryTime(TimeOutForApplication);
    })
    const currentURL = this.document.location.origin;
    if (currentURL?.includes('tfm')) {
      this.sUIService.updateTitle("File Manager");
    } else {
      this.sUIService.updateTitle(environment.Theme == 'kidm' ? 'ICM' : 'Trillo Workbench');
    }
    this.configEnv = this.sessionService.SessionService_GetApplicationSideBarConfigurationFile().env;
    console.log(this.configEnv, "env");

  }
  LoginComponent_GetConfigFile(IncomingBoolean) {
    this.oDataService.DataService_GetConfigFIle().subscribe({
      next: (res) => {
        if (res.failed) {
          console.log("failed");
        }
        else {

          const oUserDetails = this.sessionService.SessionService_GetUserDetails();
          console.log(oUserDetails, "userDetail");

          this.LoginComponent_HandleSideBarItems(res, oUserDetails);
          this.sessionService.SessionService_SetApplicationSideBarConfigurationFile(res);
          this.oDataService.UpdateSidebarItems.next(IncomingBoolean);
        }
        // this.oRoute.navigateByUrl('/datasource');
      },
      error: (err) => {
        console.log("Error==>", err);
      }
    })
  }
  LoginComponent_HandleSideBarItems(res, oUserDetails) {
    if (res.env === "saas") {
      if (oUserDetails.role == 'admin' && oUserDetails.tenantId !== '1') {
        res.menus = [
          {
            "routerLink": "/doc-manager",
            "label": "Trillo Doc AI"
          },
          {
            "routerLink": "/team-members",
            "label": "Team Members"
          }
        ]
      }
    }
    if (oUserDetails.role !== "admin") {
      const itemsToRemove = ["Admin", "Team Members", "Tasks History"];
      if (res.env !== 'saas') {
        itemsToRemove.push("Metadata Files");
      }
      if (oUserDetails.role == "userSFTP" && res.menus[0].label === "Trillo File Manager") {
        res.menus = [
          {
            "routerLink": "/filemanager",
            "label": "Trillo File Manager"
          },
          {
            "routerLink": "/settings",
            "label": "Settings"
          },
        ]
      }
      for (const itemLabel of itemsToRemove) {
        const index = res.menus.findIndex(item => item.label === itemLabel);
        if (index !== -1) {
          res.menus.splice(index, 1);
        }
      }
    }
    // Remove consecutive separators
    for (let i = 0; i < res.menus.length - 1; i++) {
      if (res.menus[i].label === "Separator" && res.menus[i + 1].label === "Separator") {
        res.menus.splice(i, 1);
        i--;
      }
    }
  }

  changeFavicon() {
    let favcon = this.document.getElementById('favicon') as HTMLLinkElement;
    favcon.href = environment.Theme == 'kidm' ? './assets/images/favicon/kidm.png' : './assets/images/favicon/trillo.png';
  }

  AppComponent_SetSessionExpiryTime(oIncomingTime) {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.oCheckIdleState.setIdle(5);
    this.oCheckIdleState.setTimeout(oIncomingTime);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.oCheckIdleState.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.oCheckIdleState.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log("On idle end Idle state : ==> ", this.idleState);
    });
    this.oCheckIdleState.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log("On Time out Idle state : ==> ", this.idleState);
      localStorage.clear();
      this.oRouter.navigateByUrl('/auth/login');
      this.AppComponent_ResetTimer();
    });
    this.oCheckIdleState.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log("On idle start Idle state : ==> ", this.idleState);
    });
    this.oCheckIdleState.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      //  console.log("on Timeout warning Idle state : ==> ", this.idleState);
    });

    // sets the ping interval to 5 seconds
    this.keepalive.interval(5);
    this.keepalive.onPing.subscribe(
      () => {
        let TimeOutForApplication = oIncomingTime;
        let rememberMe = JSON.parse(localStorage.getItem('rememberMe'));
        rememberMe == true ? TimeOutForApplication = 604800 : TimeOutForApplication = 14400;
        this.lastPing = new Date();
        const nSessionExpiryTime = this.lastPing.getTime() + TimeOutForApplication * 1000;
        localStorage.setItem("lsTrilloWorkbenchSaasExpiryTime", JSON.stringify(nSessionExpiryTime))
      });

    this.AppComponent_ResetTimer();
  }
  AppComponent_ResetTimer() {
    this.AppComponent_CheckSessionExpiry()
    this.oCheckIdleState.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    console.log("AppComponent_ResetTimer Called!!");
  }

  AppComponent_CheckSessionExpiry() {
    const oDate = new Date();
    const nSessionExpiryTime = JSON.parse(localStorage.getItem("lsTrilloWorkbenchSaasExpiryTime"));
    const nCurrentTime = oDate.getTime();
    if (nSessionExpiryTime !== null) {
      // compare the expiry time of the item with the current time
      if (nCurrentTime > nSessionExpiryTime) {
        localStorage.removeItem("lsTrilloWorkbenchSaasExpiryTime");
        localStorage.clear();
        console.log("Session_CheckSessionExpiry: Current Session is expired. So, logging out");
        this.oRouter.navigateByUrl('/auth/login');
      }
    }
  }
}
