export const navbarData = [
    {
        routerLink: '/dashboard',
        icon:'fa-home',
        label: 'Dashboard'
    },
    {
        routerLink: '/datasource',
        icon:'fa-file-pen',
        label: 'Data Sources'
    },
    {
        routerLink: '/dictionary',
        icon:'fa-solid fa-book',
        label: 'Data Dictionary'
    },
    {
        routerLink: '/queries',
        icon:'bi bi-table',
        label: 'Queries'
    },
    {
        routerLink: '/bigquery',
        icon:'fa fa-scroll',
        label: 'Big Query'
    },
    {
        routerLink: '/enablefunction',
        icon:'fa-solid fa-florin-sign',
        label: 'Functions'
    },
    {
        routerLink: '/function',
        icon:'fa-regular fa-florin-sign',
        label: 'Functions'
    },
    {
        routerLink: '/apps/workflows',
        icon:'fa-solid fa-diagram-project',
        label: 'Workflows'
    },
    {
        routerLink: '/enableschedules',
        icon:'fa-regular fa-clock',
        label: 'Schedules'
    },
    {
        routerLink: '/schedules',
        icon:'fa-regular fa-clock',
        label: 'Schedules'
    },
    {
        routerLink: '/apiplayground',
        icon:'fa-light fa-webhook',
        label: 'API Playground'
    },
    {
        routerLink: '/metadata',
        icon:'fa-regular fa-folder',
        label: 'Metadata Files'
    },
    {
        routerLink: '',
        icon:'',
        label: 'Separator'
    },
    {
        routerLink: '/apps/task-history',
        icon:'fa-solid fa-list-check',
        label: 'Tasks History'
    },


    {
        routerLink: '/logs',
        icon:'fa-box',
        label: 'Logs'
    },
    {
        routerLink: '',
        icon:'',
        label: 'Separator'
    },
    {
        routerLink: '/datamanager',
        icon:'fa-database',
        label: 'Data Browser'
    },
    {
        routerLink: '/doc-manager',
        icon:'fa-file-alt',
        label: 'Trillo Doc AI'
    },
    {
        routerLink: '/filemanager',
        icon:'fa-file',
        label: 'File Manager'
    },
    {
        routerLink: '',
        icon:'',
        label: 'Separator'
    },
    {
        routerLink: '/applications',
        icon:'fa-solid fa-code',
        label: 'Applications'
    },
    {
        routerLink: '/settings',
        icon:'fa-solid fa-gear',
        label: 'Settings'
    },
    {
        routerLink: '/manage-backups',
        icon:'fa-arrows-rotate',
        label: 'Manage Backups'
    },
    {
        routerLink: '/groups',
        icon:'fa-sharp fa-solid fa-people-group',
        label: 'Groups'
    },
    {
        routerLink: '/usermanager',
        icon:'fa-user',
        label: 'Admin'
    },
    {

        routerLink: '/team-members',
        icon:'fa-solid fa-users',
        label: 'Team Members'
    }
    
]