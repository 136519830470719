<div class="p-3 warning-modal shadow">
  <div class="modal-header mb-3">
    <h4 class="mb-0"><i class="fa-solid fa-triangle-exclamation text-warning me-2"></i> Warning</h4>
  </div>
  <div class="modal-body">
    <p class="mb-3">A file upload is in progress. Are you sure you want to leave?</p>
  </div>
  <div class="modal-footer gap-3 mt-1">
    <button class="btn btn-primary" (click)="onCancel()">Stay</button>
    <button class="btn btn-outline-secondary" (click)="onConfirm()">Leave</button>
  </div>
</div>