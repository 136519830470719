<!-- <div class="d-flex"> -->
  <!-- Non-Saas Buttons -->
  <!-- <button
  *ngIf="(viewBtn || isSchemasRoute) && !saasEnvButtons"
  ngbTooltip="Dashboard"
  container="body"
  placement="bottom"
  class="btn btn-sm btn-default navigation-buttons me-2"
  (click)="onDashboardButtonClick()"
>
  <i class="fa-regular fa-house" aria-hidden="true"></i>
</button> -->

  <button
  *ngIf="(viewBtn || isSchemasRoute) && router.url === '/doc-manager?documentType=text' && !saasEnvButtons"
  ngbTooltip="Search & QnA"
  placement="bottom"
  class="btn btn-sm btn-default navigation-buttons me-2"
  [ngClass]="oUserDetails?.role !== 'user' ? '' : 'Home-btn-user'"
  (click)="onSearchButtonClick()"
>
<i class="fa-regular fa-magnifying-glass fs-6" aria-hidden="true"></i>
</button>

  <!-- <button
    *ngIf="(viewBtn || isSchemasRoute) && !saasEnvButtons"
    ngbTooltip="New Conversation"
    placement="bottom"
    class="btn btn-sm btn-default navigation-buttons me-2"
    [ngClass]="oUserDetails?.role !== 'user' ? '' : 'Home-btn-user'"
    [class.active-btn]="
      !sharedButtons?.documents && !sharedButtons?.matching && !isSchemasRoute
    "
    (click)="onButtonClick('home')"
  >
  <i class="fa-regular fa-magnifying-glass"></i>
  </button> -->

  <!-- <button
    *ngIf="(viewBtn || isSchemasRoute) && !saasEnvButtons && !router.url.includes('app-generation')"
    ngbTooltip="Browse Documents"
    container="body"
    placement="bottom"
    class="btn btn-sm btn-default navigation-buttons me-2"
    [class.active-btn]="sharedButtons?.documents"
    (click)="onButtonClick('documents')"
  >
    <i class="fa-folder-tree fa-regular" aria-hidden="true"></i>
  </button> -->

  <!-- <button
    *ngIf="((oUserDetails?.role !== 'user' && viewBtn) || isSchemasRoute) && !saasEnvButtons"
    class="btn btn-sm btn-default navigation-buttons search-button me-2"
    ngbTooltip="Matching"
    placement="left"
    [class.active-btn]="sharedButtons?.matching"
    (click)="onButtonClick('matching')"
  >
    <i class="fa-regular fa-equals"></i>
  </button> -->

<!-- Saas Buttons -->

  <!-- <button *ngIf="(viewBtn || isSchemasRoute) && this.applications?.['Chat'] && this.saasEnvButtons && !router.url.includes('app-generation')"  
  ngbTooltip="Chat"
  container="body"
  placement="bottom"
  class="btn btn-sm btn-default view-btn mr-2"
  (click)="navigateToApplicationUrl(this.environment?.ChatApplication,'Chat')"
  >
  <i class="fa-regular fa-magnifying-glass fs-6" aria-hidden="true"></i>
  </button> -->
  <!-- <button *ngIf="(viewBtn || isSchemasRoute) && this?.applications?.['Data'] && this.saasEnvButtons && !router.url.includes('app-generation')"  class="btn btn-sm btn-default view-btn me-2"
  ngbTooltip="Data"   placement="bottom" (click)="navigateToApplicationUrl(this.environment?.DataApplication,'Data')">
  <i class="fa-regular fa-table-tree fs-6"></i>
  </button> -->
    <!-- <button 
    *ngIf="(viewBtn || isSchemasRoute) && this.saasEnvButtons && !router.url.includes('app-generation')" 
    [class.active-btn]="sharedButtons?.documents"
    ngbTooltip="Browse Documents"
    container="body"
    placement="bottom"
    class="btn btn-sm btn-default view-btn mr-2"
    (click)="switchToDocuments()">
    <i class="fa-folder-tree fa-regular" aria-hidden="true"></i>
</button>  -->
<!-- </div> -->
