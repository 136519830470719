import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cDataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-edit-prompts',
  templateUrl: './edit-prompts.component.html',
  styleUrls: ['./edit-prompts.component.scss']
})
export class EditPromptsComponent {
  selectedRowIndex: number = 0;
  @Output() closeModal = new EventEmitter<MouseEvent>();

  constructor(
    private oDataService: cDataService,
    private modalService: NgbModal

  ) { }
  bResponseAlert: boolean;
  resMessage: { message: any; responseType: any; color: any };
  bLoading: boolean = true;

  @Input() file
  @Input() completeWorkFlowList
  codeMirrorOptions: any = {
    mode: "text/plain", 
    indentWithTabs: true,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: false,
    extraKeys: { "Ctrl-Space": "autocomplete", 'Ctrl-F': 'findPersistent', 'Cmd-F': 'findPersistent', "Ctrl-Q": function (cm) { cm.foldCode(cm.getCursor()); } },
    foldGutter: true,
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    autoFocus: true,
    bracketMatches: true
  };
  promptsList: any = []
  json: any

  // ****************************************** ngOnInit ***************************************

  ngOnInit() {
    this.getPromptsList();
  }


  // --------------------getPromptsList  --------------------


  getPromptsList(action?) {
    const parentId = this.completeWorkFlowList?.id 
    const name = this.file.activityName;
    this.oDataService.DataService_GetPromptsList(parentId, name)
      .subscribe({
        next: (res) => {
          if (res.failed) {
            this.displayAlertMessage(res.message, "failed", "danger");
            this.bLoading = false

          } else {
            this.promptsList=res
          if(action==="saveNew") {
            this.json=this.promptsList[this.promptsList?.length-1].content
            this.selectedRowIndex=this.promptsList?.length-1
          }else{
            this.json=this.promptsList[this.selectedRowIndex]?.content
          }
            
            // this.getworkflowPrompt(this.completeWorkFlowList?.id,this.file.activityName )
            this.bLoading = false
          }
        },

        error: (error) => {
          this.displayAlertMessage(error.message || "An error occurred", "failed", "danger");
          this.bLoading = false
        },
        complete: () => {
          console.log("Request complete");
        },
      });
  }

  // // --------------------Get workflow prompt --------------------

  // getworkflowPrompt(parentId,name) {
  //   this.oDataService.DataService_GetworkflowPrompt(parentId, name)
  //     .subscribe({
  //       next: (res) => {
  //         if (res.failed) {
  //           this.displayAlertMessage(res.message, "failed", "danger");
  //         } else {
  //           this.json = res.content // Pretty formatting

  //         }
  //       },
  //       error: (error) => {
  //         this.displayAlertMessage(error.message || "An error occurred", "failed", "danger");
  //       },
  //       complete: () => {
  //         console.log("Request complete");
  //       },
  //     });
  // }

  // --------------------savePrompt --------------------


  savePrompts(action: string) {
    // Initialize the payload with common properties
    let body: any = {
      parentId: this.completeWorkFlowList?.id,
      name: this.file.activityName,
      workflowType: this.completeWorkFlowList?.workflowType,
      content: this.json,
    };
  
    // Add or remove `id` based on the action
    if (action === 'save') {
      body.id = this.promptsList[this.selectedRowIndex]?.id,
      body.active=this.promptsList[this.selectedRowIndex]?.active

    }
      this.oDataService.DataService_savePropmt(body)
      .subscribe({
        next: (res) => {
          if (res.failed) {
            this.displayAlertMessage(res.message, "failed", "danger");
          } else {
            this.json=res?.content,
            this.displayAlertMessage("Prompt saved successfully", "success", "success");
            this.getPromptsList(action)
            
          }
        },
        error: (error) => {
          this.displayAlertMessage(error.message || "An error occurred", "failed", "danger");
        },
        complete: () => {
          console.log("Request complete");
        },
      });
  }


  // --------------------activatePrompt --------------------

  activatePrompt() {
    let body={
      id:this.promptsList[this.selectedRowIndex].id
    }
    this.oDataService.DataService_ActivatePrompt(body)
      .subscribe({
        next: (res) => {
          if (res.failed) {
            this.displayAlertMessage(res.message, "failed", "danger");
          } else {
            this.displayAlertMessage(res.message,"success","success");
            this.getPromptsList();

          }
        },
        error: (error) => {
          this.displayAlertMessage(error.message || "An error occurred", "failed", "danger");
        },
        complete: () => {
          console.log("Request complete");
        },
      });
  }
  getPromptData(item: any, index: any) {
    this.selectedRowIndex = index;
    this.json=this.promptsList[this.selectedRowIndex]?.content
    // this.getworkflowPrompt(item?.id,item.name)
  }


  onClose() {
    this.closeModal.emit();
  }
  // ****************************************** ALERT MESSAGES ***************************************

  displayAlertMessage(
    sIncommingMessage,
    sIncommingResponseType,
    sIncommingColor
  ) {
    this.bResponseAlert = true;
    this.resMessage = {
      message: sIncommingMessage,
      responseType: sIncommingResponseType,
      color: sIncommingColor,
    };
    setTimeout(() => {
      this.bResponseAlert = false;
    }, 3000);
  }
}
