import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgbAccordionModule, NgbCarouselModule, NgbDatepicker, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './loader/loader.component';
import { AlertMessagesComponent } from './alert-messages/alert-messages.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClickOutsideDirective } from './header/click-outside.directive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NavigationButtonsComponent } from './navigation-buttons/navigation-buttons.component';
import { SchemaWorkflowComponent } from '../modules/doc-manager/components/schema-workflow/schema-workflow.component';
import { EditPromptsComponent } from '../modules/doc-manager/components/edit-prompts/edit-prompts.component';
import { AngularSplitModule } from 'angular-split';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MarkdownModule } from 'ngx-markdown';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CKEditorModule } from 'ckeditor4-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavigationModalComponent } from './navigation-modal/navigation-modal.component';
import { ExecuteWorkflowNewComponent } from '../modules/doc-manager/components/execute-workflow-new/execute-workflow-new.component';
import { SafeHtmlPipe } from '../pipes/safeHtml.pipe';


@NgModule({
  declarations: [
    HeaderComponent,SidebarComponent,
    LoaderComponent,
    AlertMessagesComponent,
    ClickOutsideDirective,
    NavigationButtonsComponent,
    SchemaWorkflowComponent,
    EditPromptsComponent,
    ExecuteWorkflowNewComponent,
    SafeHtmlPipe

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    RouterModule,
    NgbModule,
    TooltipModule.forRoot(),
    AngularSplitModule,
    NgbCarouselModule,
    NgxDocViewerModule,
    CodemirrorModule,
    MarkdownModule.forRoot(),
    MatDialogModule,
    CKEditorModule,
    DragDropModule,
    NgbAccordionModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [HeaderComponent, SidebarComponent,LoaderComponent,AlertMessagesComponent,NavigationButtonsComponent,SchemaWorkflowComponent,EditPromptsComponent,ExecuteWorkflowNewComponent,SafeHtmlPipe]
})
export class SharedModule { }
